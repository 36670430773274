import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { UneteequipoService } from './uneteequipo.service'

const form_data: FormData = new FormData();

@Component({
  selector: 'app-uneteequipo',
  templateUrl: './uneteequipo.component.html',
  styleUrls: ['./uneteequipo.component.scss']
})
export class UneteequipoComponent implements OnInit {
  mailText:string;
  text_modal:string;
  body_modal:string;

  names:string = "";
  businessname:string = "";
  phonecontact:string = "";
  cellphone:string = "";
  subject:string = "";
  email:string = "";
  message?:string;
  msgError:boolean = false;

   /* FOOTER */
   emailinfo: string = "";
   emailrh: string = "";
   emailcontact: string = "";
   telefono: string = "";
   calle: string = "";
   nointerior: string = "";
   noexterior: string = "";
   zipcode: string = "";
   colonia: string = "";
   municipio: string = "";
   estado: string = "";
   pais: string = "";
   latitud: string = "";
   longitud: string = "";

   urlmap: string = "";

  text_slogan: string = "";
  text_second: string = "";

  tblServicio: any = [];

  tblHour: any = [];

  URLUploadImg = environment.apicv;
  public image_cv: FileUploader = new FileUploader({
    url: this.URLUploadImg,
    disableMultipart: false,
    autoUpload: true,
    method: 'post',
    itemAlias: 'attachment',
    allowedFileType: ['image']
  });
  name_cv: string = "";

  constructor(
    private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    public uneteequipoService: UneteequipoService
  ) { }

  ngOnInit(): void {
    this.getExtra();
    this.getHour();
    this.getBanner();
    this.getServicio();
  }

  goHome(){
    this.router.navigate(['/home']);
  }

  goContact():void{
    this.router.navigate(['/contact']);
  }

  async sendMsg(){
    console.log(this.message);
    if(this.names == "" && (this.cellphone == "" || this.phonecontact == "") && this.email == ""){
      this.msgError = false;
    }else{
      let params = `names=${this.names}&cellphone=${this.cellphone}&phonecontact=${this.phonecontact}&email=${this.email}&emailcontact=${this.emailcontact}`;
      var sendEmaul = await this.uneteequipoService.sendEmail(params);
      this.msgError = true;
    }
  }

  async getBanner() {
    var get_banner = await this.uneteequipoService.getBanner();
    console.log(get_banner);
    if (get_banner["exito"]) {

      this.text_slogan = get_banner["body"][0]["textone"];
      this.text_second = get_banner["body"][0]["texttwo"];
    }
  }

  mailMe():void{
    this.mailText = "mailto:felipecruz@sicgaman.com+?subject=Pagina Web&body="; // add the links to body
    window.location.href = this.mailText;
  }

  async getExtra() {
    var postExtra = await this.uneteequipoService.getExtra();
    console.log(postExtra);
    if (postExtra["exito"]) {
      console.log(postExtra["body"][0]);
      this.emailinfo = postExtra["body"][0]["emailinfo"];
      this.telefono = postExtra["body"][0]["telefono"];
      this.calle = postExtra["body"][0]["calle"];
      this.nointerior = postExtra["body"][0]["nointerior"];
      this.noexterior = postExtra["body"][0]["noexterior"];
      this.zipcode = postExtra["body"][0]["zipcode"];
      this.colonia = postExtra["body"][0]["colonia"];
      this.municipio = postExtra["body"][0]["municipio"];
      this.estado = postExtra["body"][0]["estado"];
      this.pais = postExtra["body"][0]["pais"];
      this.emailcontact = postExtra["body"][0]["emailcontact"];
      this.emailrh = postExtra["body"][0]["emailrh"];
      this.latitud = postExtra["body"][0]["latitud"];
      this.longitud = postExtra["body"][0]["longitud"];

      //this.urlmap = "https://www.google.com.mx/maps/place/Av+Eloy+Cavazos+2205,+La+Quinta,+67170+Guadalupe,+N.L./@" + this.latitud + "," + this.longitud + ",17z/data=!3m1!4b1!4m8!1m2!2m1!1sEloy+Cavazos+2205,+La+Quinta,+Guadalupe,+Nuevo+Le%26oacute;n,+M%26eacute;xico!3m4!1s0x8662c0142a09a75b:0x4076fcd5e177efde!8m2!3d25.6627753!4d-100.2408772";
      this.urlmap = "https://maps.google.com/maps?q=" + encodeURIComponent(this.calle+','+this.nointerior+','+this.colonia+','+this.municipio+','+this.estado+','+this.pais+','+this.zipcode)+ "&t=&z=13&ie=UTF8&iwloc=&output=embed"
      console.log(this.urlmap);
    }
  }

  goDirection():void{
    window.open("https://www.google.com.mx/maps/place/Av+Eloy+Cavazos+2205,+La+Quinta,+67170+Guadalupe,+N.L./@" + this.latitud + "," + this.longitud + ",17z/data=!3m1!4b1!4m8!1m2!2m1!1sEloy+Cavazos+2205,+La+Quinta,+Guadalupe,+Nuevo+Le%26oacute;n,+M%26eacute;xico!3m4!1s0x8662c0142a09a75b:0x4076fcd5e177efde!8m2!3d25.6627753!4d-100.2408772", "_blank");
  }

  async viewService(content, number_service) {

    this.text_modal = this.tblServicio[number_service]['header'];
    this.body_modal = this.tblServicio[number_service]['texto'];

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {


    }, (reason) => {
      console.log("reason" + reason);
    });
  }

  async getServicio() {
    var get_servicio = await this.uneteequipoService.getServicios();
    console.log(get_servicio);
    if (get_servicio["exito"]) {
      this.tblServicio = get_servicio["body"];
    }
  }

  async getHour() {
    var get_hour = await this.uneteequipoService.getHour();
    console.log(get_hour);
    if (get_hour["exito"]) {
      this.tblHour = get_hour["body"];
    }
  }

  doTextareaValueChange(ev) {
    try {
      this.message = ev.target.value;
    } catch(e) {
      console.info('could not set textarea-value');
    }
  }

  onFileSelect(event) {
    if (this.image_cv.queue.length > 0) {

      for (var i = 0; i < this.image_cv.queue.length; i++) {

        // this.saveimage(this.uploaderImage.queue[i], this.idScope)
        // console.log(this.idScope);
        form_data.append('avatar', this.image_cv.queue[0].file.rawFile, this.image_cv.queue[0].file.name);
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        // this.HttpClient.post(this.URLUploadImg, formData, { headers: headers })
        this.http.post(this.URLUploadImg, form_data, { headers: headers })
          .subscribe(dataFiles => {

            console.log(dataFiles['body']);
              this.name_cv = dataFiles['body'];
          });
      }
      this.image_cv.queue.length = 0;
    }
  }

}
