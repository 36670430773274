<header class="header">
    <nav class="navbar navbar-expand-lg navbar-light py-3 bg-white">
        <div class="container">
            <a class="navbar-brand">
                <img src="assets/img/gamant.png" alt="" width="150">
            </a>
            <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item mx-2">
                        <!-- Link--><a class="nav-link" style="cursor: pointer;" (click)="scroll(sevice)">Servicios</a>
                    </li>
                    <li class="nav-item mx-2">
                        <!-- Link--><a class="nav-link" style="cursor: pointer;" [routerLink]="'/contact'">Contactanos</a>
                    </li>
                    <li class="nav-item mx-2">
                        <!-- Link--><a class="nav-link" style="cursor: pointer;" [routerLink]="'/uneteequipo'">Unete a nuestro equipo</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
<!-- Hero Slider -->
<section>
    <div class="dark-overlay">
        <div class="py-5 text-white overlay-content">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7 text-center text-lg-left mb-4 mb-lg-0">
                        <h2 class="h3 mb-3">
                            {{text_slogan}}
                        </h2>
                        <p class="text-small mb-0">
                            {{text_second}}
                        </p>
                    </div>
                    <div class="col-lg-5 text-center text-lg-right intro" (click)="scroll(target)">
                        <a class="icon faa-float animated scroll-to">
                            <i class="fa fa-angle-double-down"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Quienes somos -->
<section class="bg-aqua">
    <div class="container py-5">
        <div class="row">
            <div class="col-lg-6 order-2 order-lg-1">
                <p class="h6 mb-1 text-uppercase text-primary mb-3">&iquest;Quienes somos?</p>
                <h2 class="mb-4" style="color: gray;">Nosotros somos...</h2>
                <p class="text-white-bold">
                    {{text_about_us}}
                </p>
                <ul class="list-check list-unstyled row px-3 mb-4">
                    <li class="text-white-bold col-lg-6 mb-2" *ngFor="let somos of tblSomos; let i = index">
                        <i class="fa fa-check-circle"
                            style=" position: absolute;  top: 10%; left: 0; color: green; font-weight: 900;"></i>
                        &nbsp;
                        {{somos.text}}
                    </li>
                </ul>
            </div>
            <div class="col-lg-5 ml-auto mb-4 mb-lg-0 order-1 order-lg-2 row">
                <div class="col-4" *ngFor="let certificados of tblCertificados; let i = index" data-toggle="tooltip"
                    data-placement="bottom" title="{{certificados.nombre}}" style="margin: 15px;">
                    <img class="img-fluid" src="{{uri_certificado}} {{certificados.imagen}}" alt="" width="200"
                        height="200">
                </div>
            </div>
        </div>
    </div>

    <div class="container py-5" #target>
        <header class="text-center mb-5">
            <div class="row">
                <div class="col-lg-7 mx-auto">
                    <h2 class="text-uppercase"><span class="text-primary">Podemos </span>Apoyarte</h2>
                </div>
            </div>
        </header>
        <div class="row">

            <div class="col-sm-6" *ngFor="let pasos of tblPasos; let i = index">
                <div class="box box-services">
                    <div class="icon"><i class="{{pasos.imagen}}"></i></div>
                    <h4 class="services-heading" style="color: #7171718c;"><strong>{{pasos.header}}.</strong></h4>
                    <p>{{pasos.texto}}.</p>
                </div>
            </div>
        </div>
    </div>

    <!-- Nuestros Servicios -->
    <div class="container py-5" #sevice>
        <header class="text-center mb-5">
            <div class="row">
                <div class="col-lg-7 mx-auto">
                    <h2 class="text-uppercase"><span class="text-primary">Nuestros </span>Servicios</h2>
                </div>
            </div>
        </header>
        <div class="row text-center carousel">

            <div class="col-lg-4 mb-4 mb-lg-0 slide" *ngFor="let servicios of tblServicio; let i = index"
                @carouselAnimation>
                <div class="shadow-sm box" *ngIf="i >= minServicios && i <= maxServicios">
                    <div class="with-border-image-sm" (click)="viewService(content, i)">
                        <div class="position-relative overflow-hidden d-block">
                            <div class="box-overlay">
                                <a class="d-block w-100 h-100"></a>
                            </div>
                            <img class="w-100" src="{{uri_servicio}}{{servicios.imagen}}" alt="" />
                        </div>
                    </div>
                    <div class="p-4 border bg-white">
                        <h3 class="h5" style="color: #7171718c;"> <a class="reset-anchor">{{servicios.header}}</a></h3>
                        <a class="btn btn-primary btn-sm text-white" (click)="viewService(content, i)">Conoce mas!</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center">
            <ol class="carousel-indicator">
                <li *ngFor="let points of qtdServicios; let i = index;" class="{{qtdServicios[i]}}"
                    (click)="onNextServicio();"></li>
            </ol>
        </div>
    </div>

    <!-- Works Section -->
    <div class="container py-5">
        <!-- carousel -->
        <div class="carousel row">
            <ng-container *ngFor="let slide of tblClients; let i = index">
                <img *ngIf="i >= minClientes && i <= maxClientes" src="{{uri_empresa}}{{slide.imagen}}"
                    class="slide col-xs-12 col-lg-4 img-client" @carouselAnimation alt="{{slide.nombre}}" />
            </ng-container>
        </div>

        <div class="row text-center" style="margin-top: 20px;">
            <ul class="owl-carousel customers owl-theme" style="display: block; opacity: 1;">
                <div class="owl-controls clickable" style="display: block;">
                    <div class="owl-pagination">
                        <div *ngFor="let points of qtdClientPoints; let i = index;"
                            class="owl-page {{qtdClientPoints[i]}}" (click)="onNextClientes();">
                            <span class=""></span>
                        </div>
                    </div>
                </div>
            </ul>
            <!-- /.owl-carousel -->
        </div>

    </div>

    <!-- Testimonials Section -->
    <div class="container py-5">
        <header class="text-center mb-5">
            <div class="row">
                <div class="col-lg-7 mx-auto">
                    <h2 class="text-uppercase">Lo <span class="text-primary">que dicen</span> nuestros clientes</h2>
                </div>
            </div>
        </header>
        <div class="row box-center" *ngFor="let servicios of tblInfoEmpresa; let i = index" @carouselAnimation>
            <div class="row box-center" *ngIf="i >= minInfoClientes && i <= maxInfoClientes">
                <div class="bg-gray p-4 p-lg-5 box-testimonials">
                    <div class="row">
                        <div class="col-lg-10">
                            <div class="d-flex">
                                <div class="flex-grow-1" style="min-width: 60px">
                                    <img class="w-100" src="{{uri_empresa}}{{servicios.imagen}}"
                                        alt="{{servicios.nombre_cliente}}" width="60" height="60" />
                                </div>
                                <div class="ml-3 flex-grow-1">
                                    <h3 class="h5" style="color: #1df0f0">{{servicios.nombre_cliente}}</h3>
                                    <ul class="list-inline small mb-4" *ngIf="servicios.estrellas == 5">
                                        <li class="list-inline-item m-0" *ngFor="let i of Arr(5).fill(1)"><i
                                                class="fa fa-star text-warning"></i></li>
                                    </ul>
                                    <ul class="list-inline small mb-4" *ngIf="servicios.estrellas == 4">
                                        <li class="list-inline-item m-0" *ngFor="let i of Arr(4).fill(1)"><i
                                                class="fa fa-star text-warning"></i></li>
                                        <i class="fa fa-star-o" style="color: black"></i>
                                    </ul>
                                    <ul class="list-inline small mb-4" *ngIf="servicios.estrellas == 3">
                                        <li class="list-inline-item m-0" *ngFor="let i of Arr(3).fill(1)"><i
                                                class="fa fa-star text-warning"></i></li>
                                        <i class="fa fa-star-o" style="color: black"></i>
                                        <i class="fa fa-star-o" style="color: black"></i>
                                    </ul>
                                    <ul class="list-inline small mb-4" *ngIf="servicios.estrellas == 2">
                                        <li class="list-inline-item m-0" *ngFor="let i of Arr(2).fill(1)"><i
                                                class="fa fa-star text-warning"></i></li>
                                        <i class="fa fa-star-o" style="color: black"></i>
                                        <i class="fa fa-star-o" style="color: black"></i>
                                        <i class="fa fa-star-o" style="color: black"></i>
                                    </ul>
                                    <ul class="list-inline small mb-4" *ngIf="servicios.estrellas == 1">
                                        <li class="list-inline-item m-0" *ngFor="let i of Arr(4).fill(1)"><i
                                                class="fa fa-star text-warning"></i></li>
                                        <i class="fa fa-star-o" style="color: black"></i>
                                        <i class="fa fa-star-o" style="color: black"></i>
                                        <i class="fa fa-star-o" style="color: black"></i>
                                        <i class="fa fa-star-o" style="color: black"></i>
                                    </ul>
                                    <div class="text-small text-muted" [innerHTML]="servicios.texto"></div>
                                    <h6 class="mb-0" *ngIf="servicios.nombre != ''">{{servicios.nombre}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 d-none d-lg-block text-right"><i
                                class="fa fa-quote-right fa-2x text-primary"></i></div>
                    </div>

                    <!-- controls -->
                    <button class="control prev" (click)="onPreviousInfoClientes()">
                        <span class="arrow left"></span>
                    </button>
                    <button class="control next" (click)="onNextInfoClientes()">
                        <span class="arrow right"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Evidencias -->
    <div class="container py-5">
        <header class="text-center mb-5">
            <div class="row">
                <div class="col-lg-7 mx-auto">
                    <h2 class="text-uppercase">Nuestro <span class="text-primary">Trabajos</span></h2>
                </div>
            </div>
        </header>
    </div>

    <div class="container-fluid">
        <div class="row no-space">
            <div class="col-sm-4 col-md-3" *ngFor="let evidencia of tblEvidencia; let i = index">
                <div class="box" style="margin: 0 -15px;">
                    <a title=""><img src="{{uri_evidencia}}{{evidencia.imagen}}" alt="" class="img-responsive"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<footer class="text-white bg-cover bg-center" style="background: url(img/hero-banner-1.jpg)">
    <div class="dark-overlay">
        <div class="overlay-content">
            <div class="container py-5">
                <div class="row py-5">
                    <div class="col-lg-4 col-md-6">
                        <img class="mb-4" src="assets/img/logo-white.png" alt="" width="120">
                        <p class="text-light text-small mb-4">{{text_slogan}}</p>

                        <p class="mb-1" (click)="mailMe()" target="_top" style="cursor: pointer;">
                            <i class="fa fa-envelope mr-3 fa-fw" style="color: #1df0f0;"></i>
                            <span class="text-small">{{emailinfo}}</span>
                        </p>
                        <p class="mb-1">
                            <i class="fa fa-mobile mr-3 fa-fw" style="color: #1df0f0;"></i>
                            <span class="text-small">{{telefono}}</span>
                        </p>
                        <p class="mb-1" (click)="goDirection()" style="cursor: pointer;">
                            <i class="fa fa-map-marker mr-3 fa-fw" style="color: #1df0f0;"></i>
                            <span class="text-small">{{calle}} {{nointerior}} {{noexterior}}, {{colonia}},
                                {{municipio}}, {{estado}}, {{pais}} {{zipcode}}</span>
                        </p>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <h6 class="text-uppercase mt-3 mb-4" style="color: #1df0f0;">Quick <span
                                class="text-white">links</span>
                        </h6>
                        <ul class="list-unstyled">
                            <li class="mb-2"><a class="footer-link" style="cursor: pointer;"
                                    (click)="scroll(target)">Podemos apoyarte!</a></li>
                            <li class="mb-2"><a class="footer-link" [routerLink]="'/contact'">Contactanos!</a></li>
                            <li class="mb-2"><a class="footer-link" [routerLink]="'/uneteequipo'">Unete a nuestro equipo!</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <h6 class="text-uppercase mt-3 mb-4" style="color: #1df0f0;">
                            Nuestros <span class="text-white">servicios</span>
                        </h6>
                        <ul class="list-unstyled">
                            <!--<li class="mb-2"><a class="footer-link" style="cursor: pointer;"
                                    (click)="viewService(content, 1)">Limpieza Industrial</a></li>
                            <li class="mb-2"><a class="footer-link" style="cursor: pointer;"
                                    (click)="viewService(content, 2)">Sorteo y Retrabajos</a></li>
                            <li class="mb-2"><a class="footer-link" style="cursor: pointer;"
                                    (click)="viewService(content, 3)">Fabricación de equipo</a></li>-->
                            <li class="mb-1" *ngFor="let servicios of tblServicio; let i = index">
                                <a class="footer-link" style="cursor: pointer;" (click)="viewService(content, i)">
                                    {{servicios.header}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <h6 class="text-uppercase mt-3 mb-4" style="color: #1df0f0;">
                            Horarios de<span class="text-white"> apertura</span>
                        </h6>
                        <ul class="list-unstyled mb-0">
                            <li *ngFor="let hours of tblHour; let i = index"
                                class="d-flex align-items-center justify-content-between {{i == 0 ? 'pb-2' : 'py-1'}}">
                                <p class="text-small mb-0">{{hours.dia_semana}} </p>
                                <span class="text-small mb-0"
                                    style="color: {{hours.hora_inicio != 'Cerrado' ? '' : '#1df0f0;'}}">{{hours.hora_inicio}}
                                    {{hours.hora_inicio != 'Cerrado' ? '-' : ''}}
                                    {{hours.hora_inicio != 'Cerrado' ? hours.hora_fin : ''}}</span>
                            </li>



                            <!--<li class="d-flex align-items-center justify-content-between pb-2">
                                <p class="text-small mb-0">Lunes </p>
                                <span class="text-small mb-0">9:00 am - 06:00 pm</span>
                            </li>
                            <li class="d-flex align-items-center justify-content-between py-1">
                                <p class="text-small mb-0">Martes </p>
                                <span class="text-small mb-0">9:00 am - 06:00 pm</span>
                            </li>
                            <li class="d-flex align-items-center justify-content-between py-1">
                                <p class="text-small mb-0">Miercoles </p>
                                <span class="text-small mb-0">9:00 am - 06:00 pm</span>
                            </li>
                            <li class="d-flex align-items-center justify-content-between py-1">
                                <p class="text-small mb-0">Jueves</p>
                                <span class="text-small mb-0">9:00 am - 06:00 pm</span>
                            </li>
                            <li class="d-flex align-items-center justify-content-between py-1">
                                <p class="text-small mb-0">Viernes</p>
                                <p class="text-small mb-0">9:00 am - 06:00 pm</p>
                            </li>
                            <li class="d-flex align-items-center justify-content-between py-1">
                                <p class="text-small mb-0">Sabado</p>
                                <p class="text-small mb-0">9:00 am - 01:00 pm</p>
                            </li>
                            <li class="d-flex align-items-center justify-content-between py-1">
                                <p class="text-small mb-0">Domingo</p>
                                <p class="text-small mb-0 text-uppercase" style="color: #1df0f0;">Cerrado</p>
                            </li>-->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container text-center">
                <div class="border-top py-4">
                    <p class="mb-0 text-white text-small">
                        &copy; All rights reserved. designed by <a href="home">CoffeHolik</a>.</p>
                </div>
            </div>
        </div>
    </div>
</footer>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{text_modal}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [innerHTML]="body_modal"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-white" (click)="modal.close('close')">Cerrar</button>
    </div>
</ng-template>