import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  uriextra = environment.apiuri+ 'ExtraController.php';
  urihour = environment.apiuri+ 'HorasController.php';
  uribanner = environment.apiuri+ 'BannerController.php';
  uriquien = environment.apiuri+ 'QuienesSomosController.php';
  urisomos = environment.apiuri+ 'SomosController.php';
  uricertificados = environment.apiuri+ 'CertificadosController.php';
  uripasos = environment.apiuri+ 'PasosController.php';
  uriservicio = environment.apiuri+ 'ServiciosController.php';
  uriempresa = environment.apiuri+ 'EmpresasController.php';
  uriinfoempresa = environment.apiuri+ 'InfoClientesController.php';
  urievidencia = environment.apiuri+ 'EvidenciasController.php';

  constructor(
    private httpClient: HttpClient,
    public router: Router
  ) { }


  async getExtra(): Promise<any> {
    const params = "";
    const response = await this.httpClient.get(`${this.uriextra}`).toPromise();
    return response;
  }

  async getHour(): Promise<any> {
    const response = await this.httpClient.get(`${this.urihour}`).toPromise();
    return response;
  }

  async getBanner(): Promise<any> {
    const response = await this.httpClient.get(`${this.uribanner}`).toPromise();
    return response;
  }

  async getQuien(): Promise<any> {
    const response = await this.httpClient.get(`${this.uriquien}`).toPromise();
    return response;
  }

  async getSomos(): Promise<any> {
    const response = await this.httpClient.get(`${this.urisomos}`).toPromise();
    return response;
  }

  async getCertificados(): Promise<any> {
    const response = await this.httpClient.get(`${this.uricertificados}`).toPromise();
    return response;
  }

  async getPasos(): Promise<any> {
    const response = await this.httpClient.get(`${this.uripasos}`).toPromise();
    return response;
  }

  async getServicios(): Promise<any> {
    const response = await this.httpClient.get(`${this.uriservicio}`).toPromise();
    return response;
  }

  async getEmpresas(): Promise<any> {
    const response = await this.httpClient.get(`${this.uriempresa}`).toPromise();
    return response;
  }

  async getInfoEmpresas(): Promise<any> {
    const response = await this.httpClient.get(`${this.uriinfoempresa}`).toPromise();
    return response;
  }

  async getEvidencia(): Promise<any> {
    const response = await this.httpClient.get(`${this.urievidencia}`).toPromise();
    return response;
  }
}
