import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {

  uriextra = environment.apiuri+ 'ExtraController.php';
  urihour = environment.apiuri+ 'HorasController.php';
  uribanner = environment.apiuri+ 'BannerController.php';
  uriquien = environment.apiuri+ 'QuienesSomosController.php';
  urisomos = environment.apiuri+ 'SomosController.php';
  uricertificados = environment.apiuri+ 'CertificadosController.php';
  uripasos = environment.apiuri+ 'PasosController.php';
  uriservicio = environment.apiuri+ 'ServiciosController.php';
  uriempresa = environment.apiuri+ 'EmpresasController.php';
  uriinfoempresa = environment.apiuri+ 'InfoClientesController.php';
  urievidencia = environment.apiuri+ 'EvidenciasController.php';

  constructor(
    private httpClient: HttpClient,
    public router: Router
  ) { }


  async getExtra(): Promise<any> {
    const params = "";
    const response = await this.httpClient.get(`${this.uriextra}`).toPromise();
    return response;
  }

  async putExtra(): Promise<any> {
    const params = "";
    const response = await this.httpClient.put(`${this.uriextra}`, {headers: new HttpHeaders().set('Content-Type', 'text/html; charset=UTF-8')}).toPromise();
    return response;
  }

  async postExtra(object): Promise<any> {
    const params = object;
    const response = await this.httpClient.post(this.uriextra,params,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async getHour(): Promise<any> {
    const response = await this.httpClient.get(`${this.urihour}`).toPromise();
    return response;
  }

  async postHour(object): Promise<any> {
    const params = object;
    const response = await this.httpClient.post(this.urihour,params,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async deleteHour(id_hour){
    const response = await this.httpClient.get(`${this.urihour}?type=update&id_hora=${id_hour}`).toPromise();
    return response;
  }

  async getBanner(): Promise<any> {
    const response = await this.httpClient.get(`${this.uribanner}`).toPromise();
    return response;
  }

  async postBanner(object): Promise<any> {
    const params = object;
    const response = await this.httpClient.post(this.uribanner,params,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async getQuien(): Promise<any> {
    const response = await this.httpClient.get(`${this.uriquien}`).toPromise();
    return response;
  }

  async postQuien(object): Promise<any> {
    const params = object;
    const response = await this.httpClient.post(this.uriquien,params,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async getSomos(): Promise<any> {
    const response = await this.httpClient.get(`${this.urisomos}`).toPromise();
    return response;
  }

  async postSomos(object): Promise<any> {
    const params = object;
    const response = await this.httpClient.post(this.urisomos,params,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async deleteSomos(id_somos){
    const response = await this.httpClient.get(`${this.urisomos}?type=update&id_quienessomospuntos=${id_somos}`).toPromise();
    return response;
  }

  async getCertificados(): Promise<any> {
    const response = await this.httpClient.get(`${this.uricertificados}`).toPromise();
    return response;
  }

  async postCertificados(object): Promise<any>{
    //const params = object;
    const response = await this.httpClient.post(this.uricertificados,object,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async deleteCertificados(id_certificados){
    const response = await this.httpClient.get(`${this.uricertificados}?type=update&id_certificados=${id_certificados}`).toPromise();
    return response;
  }

  async getPasos(): Promise<any> {
    const response = await this.httpClient.get(`${this.uripasos}`).toPromise();
    return response;
  }

  async postPasos(object): Promise<any> {
    const params = object;
    const response = await this.httpClient.post(this.uripasos,params,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async deletePasos(id_pasos){
    const response = await this.httpClient.get(`${this.uripasos}?type=update&id_pasos=${id_pasos}`).toPromise();
    return response;
  }

  async getServicios(): Promise<any> {
    const response = await this.httpClient.get(`${this.uriservicio}`).toPromise();
    return response;
  }

  async postServicios(object): Promise<any> {
    const params = object;
    const response = await this.httpClient.post(this.uriservicio,params,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async deleteServicios(id_servicios){
    const response = await this.httpClient.get(`${this.uriservicio}?type=update&id_servicios=${id_servicios}`).toPromise();
    return response;
  }

  async getEmpresas(): Promise<any> {
    const response = await this.httpClient.get(`${this.uriempresa}`).toPromise();
    return response;
  }

  async postEmpresas(object): Promise<any> {
    const params = object;
    const response = await this.httpClient.post(this.uriempresa,params,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async deleteEmpresas(id_clientes){
    const response = await this.httpClient.get(`${this.uriempresa}?type=update&id_clientes=${id_clientes}`).toPromise();
    return response;
  }

  async getInfoEmpresas(): Promise<any> {
    const response = await this.httpClient.get(`${this.uriinfoempresa}`).toPromise();
    return response;
  }

  async postInfoEmpresas(object): Promise<any> {
    const params = object;
    const response = await this.httpClient.post(this.uriinfoempresa,params,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async deleteInfoEmpresas(id_infoclientes){
    const response = await this.httpClient.get(`${this.uriinfoempresa}?type=update&id_infoclientes=${id_infoclientes}`).toPromise();
    return response;
  }

  async getEvidencia(): Promise<any> {
    const response = await this.httpClient.get(`${this.urievidencia}`).toPromise();
    return response;
  }

  async postEvidencia(object): Promise<any> {
    const params = object;
    const response = await this.httpClient.post(this.urievidencia,params,{headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).toPromise();
    return response;
  }

  async deleteEvidencia(id_evidencia){
    const response = await this.httpClient.get(`${this.urievidencia}?type=update&id_evidencia=${id_evidencia}`).toPromise();
    return response;
  }
}
