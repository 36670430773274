<nav class="navbar navbar-expand-lg navbar-dark-mode bg-dark-mode">
    <a class="navbar-brand" href="#">
        <img src="assets/img/gamant.png" alt="logo" width="150">
    </a>
    <button class="navbar-toggler collapsed" type="button" (click)="toggleState()">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse w-100 order-3 dual-collapse2" [ngClass]="{ 'collapse': isCollapse }">
        <ul class="navbar-nav ml-auto">
            <li routerLinkActive="active" class="nav-item">
                <a class="nav-link" routerLink="/dashboard">Dashboard <span class="sr-only">(current)</span></a>
            </li>
            <li routerLinkActive="active" class="nav-item">
                <a class="nav-link" routerLink="/configuracion">Configaci&oacute;n</a>
            </li>
        </ul>
    </div>
</nav>

<section class="bg-aqua">
    <div class="container py-5">
        <div class="row">
            <div class="col-12">
                <h1 class="mb-4">Bienvenido al portal de administraci&oacute;n</h1>
                <p>
                    Actualmente el portal podrá adminitrar el landing de nuestro portal actual.<br />

                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>Incluye Mini banner:</p>
                <ul>
                    <li>Texto Slogan</li>
                    <li>Texto Secundaria (Visión/Misión/Sub-Slogan)</li>
                </ul>
            </div>
        </div>
    </div>
</section>