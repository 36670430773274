import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
declare var jQuery: any;

import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string = "";
  pass: string = "";
  msjerror: string = "";
  msjerrorbd: string = "";

  constructor(
    private loginService:LoginService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    (function ($) {
      "use strict";


      /*==================================================================
      [ Focus input ]*/
      $('.input100').each(function () {
        $(this).on('blur', function () {
          if ($(this).val().trim() != "") {
            $(this).addClass('has-val');
          }
          else {
            $(this).removeClass('has-val');
          }
        })
      })


      /*==================================================================
      [ Validate ]*/
      var input = $('.validate-input .input100');

      $('.validate-form').on('submit', function () {
        var check = true;

        for (var i = 0; i < input.length; i++) {
          if (validate(input[i]) == false) {
            showValidate(input[i]);
            check = false;
          }
        }

        return check;
      });


      $('.validate-form .input100').each(function () {
        $(this).focus(function () {
          hideValidate(this);
        });
      });

      function validate(input) {
        if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
          if ($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
            return false;
          }
        }
        else {
          if ($(input).val().trim() == '') {
            return false;
          }
        }
      }

      function showValidate(input) {
        var thisAlert = $(input).parent();
        $(thisAlert).addClass('alert-validate');
      }

      function hideValidate(input) {
        var thisAlert = $(input).parent();
        $(thisAlert).removeClass('alert-validate');
      }
    })(jQuery);
  }

  async logIn(){
    this.msjerrorbd = "", this.msjerror = "";
    if(this.username == "" && this.pass == ""){
      this.msjerror = "Favor de ingresar ingresar el usuario y/o contraseña";
    }else{
      var loginService = await this.loginService.loginUser(this.username, this.pass);
      console.log(loginService);
      if(loginService['exito']){
        localStorage.setItem("user", JSON.stringify(loginService['body']));
        console.log("exito");
        this.router.navigate(['/dashboard']);
      }else{
        console.log("error");
        this.msjerrorbd = loginService['menssage'];
      }
    }
  }
}