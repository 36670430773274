import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { trigger, transition, style, animate } from "@angular/animations";
declare var $: any;
declare var jQuery: any;

import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {

  uri_certificado = environment.api_certificado;
  uri_servicio = environment.api_servicio;
  uri_empresa = environment.api_empresa;
  uri_evidencia = environment.api_evidencia;

  text_slogan: string = "";
  text_second: string = "";

  text_about_us: string = "";

  tblSomos: any = [];

  interval;
  public timeLeft: number = 60;

  mailText: string;
  text_modal: string;
  body_modal: string;

  /* FOOTER */
  emailinfo: string = "";
  emailrh: string = "";
  emailcontact: string = "";
  telefono: string = "";
  calle: string = "";
  nointerior: string = "";
  noexterior: string = "";
  zipcode: string = "";
  colonia: string = "";
  municipio: string = "";
  estado: string = "";
  pais: string = "";
  latitud: string = "";
  longitud: string = "";

  tblHour: any = [];

  tblCertificados: any = [];

  tblPasos: any = [];

  minServicios: number = 0;
  maxServicios: number = 0;
  ptServicios: number = 0;
  qtdServicios: any = [];
  tblServicio: any = [];

  minClientes: number = 0;
  maxClientes: number = 0;
  ptClientes: number = 0;
  qtdClientPoints: any = [];
  tblClients: any = [];

  minInfoClientes: number = 0;
  maxInfoClientes: number = 0;
  ptInfoClientes: number = 0;
  qtdInfoClientPoints: any = [];
  tblInfoEmpresa: any = [];
  Arr = Array;

  tblEvidencia:any = [];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {


    this.getExtra();
    this.getHour();
    this.getBanner();
    this.getQuien();
    this.getCertificados();
    this.getPasos();
    this.getServicio();
    this.getClient();
    this.getInfoClientes();
    this.getEvidencias();

    this.startTimerServicios();
    this.startTimerClientes();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  goContact(): void {
    this.router.navigate(['/contact']);
  }

  mailMe(): void {
    this.mailText = "mailto:felipecruz@sicgaman.com+?subject=Pagina Web&body="; // add the links to body
    window.location.href = this.mailText;
  }

  goDirection(): void {
    window.open("https://www.google.com.mx/maps/place/Av+Eloy+Cavazos+2205,+La+Quinta,+67170+Guadalupe,+N.L./@" + this.latitud + "," + this.longitud + ",17z/data=!3m1!4b1!4m8!1m2!2m1!1sEloy+Cavazos+2205,+La+Quinta,+Guadalupe,+Nuevo+Le%26oacute;n,+M%26eacute;xico!3m4!1s0x8662c0142a09a75b:0x4076fcd5e177efde!8m2!3d25.6627753!4d-100.2408772", "_blank");
  }

  async viewService(content, number_service) {

    this.text_modal = this.tblServicio[number_service]['header'];
    this.body_modal = this.tblServicio[number_service]['texto'];

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {


    }, (reason) => {
      console.log("reason" + reason);
    });
  }

  async getExtra() {
    var postExtra = await this.homeService.getExtra();
    console.log(postExtra);
    if (postExtra["exito"]) {
      console.log(postExtra["body"][0]);
      this.emailinfo = postExtra["body"][0]["emailinfo"];
      this.telefono = postExtra["body"][0]["telefono"];
      this.calle = postExtra["body"][0]["calle"];
      this.nointerior = postExtra["body"][0]["nointerior"];
      this.noexterior = postExtra["body"][0]["noexterior"];
      this.zipcode = postExtra["body"][0]["zipcode"];
      this.colonia = postExtra["body"][0]["colonia"];
      this.municipio = postExtra["body"][0]["municipio"];
      this.estado = postExtra["body"][0]["estado"];
      this.pais = postExtra["body"][0]["pais"];
      this.emailcontact = postExtra["body"][0]["emailcontact"];
      this.emailrh = postExtra["body"][0]["emailrh"];
      this.latitud = postExtra["body"][0]["latitud"];
      this.longitud = postExtra["body"][0]["longitud"];
    }
  }

  async getHour() {
    var get_hour = await this.homeService.getHour();
    console.log(get_hour);
    if (get_hour["exito"]) {
      this.tblHour = get_hour["body"];
    }
  }

  async getBanner() {
    var get_banner = await this.homeService.getBanner();
    console.log(get_banner);
    if (get_banner["exito"]) {

      this.text_slogan = get_banner["body"][0]["textone"];
      this.text_second = get_banner["body"][0]["texttwo"];
    }
  }

  async getQuien() {
    var get_quien = await this.homeService.getQuien();
    console.log(get_quien);
    if (get_quien["exito"]) {

      this.text_about_us = get_quien["body"][0]["text"];
    }
  }

  async getSomos() {
    var get_somos = await this.homeService.getSomos();
    console.log(get_somos);
    if (get_somos["exito"]) {
      this.tblSomos = get_somos["body"];
    }
  }

  async getCertificados() {
    var getCertificados = await this.homeService.getCertificados();
    console.log(getCertificados);
    if (getCertificados["exito"]) {
      this.tblCertificados = getCertificados["body"];
    }
  }

  async getPasos() {
    var get_pasos = await this.homeService.getPasos();
    console.log(get_pasos);
    if (get_pasos["exito"]) {
      this.tblPasos = get_pasos["body"];
    }
  }

  async getServicio() {
    var get_servicio = await this.homeService.getServicios();
    console.log(get_servicio);
    if (get_servicio["exito"]) {
      this.tblServicio = get_servicio["body"];
    }

    var total = (window.innerWidth > 300 && window.innerWidth <= 768) ? this.tblServicio.length : Math.floor(this.tblServicio.length / 3);
    var residue = (window.innerWidth > 300 && window.innerWidth <= 768) ? 0 : this.tblServicio.length % 3;
    var totalpoints = (residue <= 0) ? total : total + residue;

    this.minServicios = 0;
    this.maxServicios = (window.innerWidth > 300 && window.innerWidth <= 768) ? 0 : 2;

    for (var i = 0; i < totalpoints; i++) {
      this.qtdServicios[i] = (i == 0) ? 'active' : '';
    }
  }

  onPreviousServicio() {
    const less = (window.innerWidth > 300 && window.innerWidth <= 768) ? 1 : 3;
    const previous = this.maxServicios - less;
    this.maxServicios = (previous <= 0) ? 0 : (previous > (this.tblServicio.length - 1)) ? 0 : previous;
    this.minServicios = (window.innerWidth > 300 && window.innerWidth <= 768) ? this.maxServicios : ((this.maxServicios - 2) > 0) ? this.maxServicios - 2 : 0;

    this.ptServicios = (this.minServicios == 1) ? 0 : this.ptServicios + 1;
    for (var i = 0; i < this.qtdServicios.length; i++) {
      this.qtdServicios[i] = (i == this.ptServicios) ? 'active' : '';
    }
  }

  onNextServicio() {
    const more = (window.innerWidth > 300 && window.innerWidth <= 768) ? 1 : 3;
    const maxServicios = this.maxServicios + more;
    this.maxServicios = ((this.tblServicio.length - this.maxServicios) > 0) ? maxServicios : (window.innerWidth > 300 && window.innerWidth <= 768) ? 0 : 2;
    this.minServicios = (window.innerWidth > 300 && window.innerWidth <= 768) ? this.minServicios : (this.maxServicios > 0) ? this.maxServicios - 2 : 0;

    this.ptServicios = (this.minServicios == 0) ? 0 : this.ptServicios + 1;
    for (var i = 0; i < this.qtdServicios.length; i++) {
      this.qtdServicios[i] = (i == this.ptServicios) ? 'active' : '';
    }
  }

  async getClient() {
    var get_empresas = await this.homeService.getEmpresas();
    console.log(get_empresas);
    if (get_empresas["exito"]) {
      this.tblClients = get_empresas["body"];
    }


    var total = (window.innerWidth > 300 && window.innerWidth <= 768) ? this.tblClients.length : Math.floor(this.tblClients.length / 3);
    var residue = (window.innerWidth > 300 && window.innerWidth <= 768) ? 0 : this.tblClients.length % 3;
    var totalpoints = (residue <= 0 && this.tblClients.length > 3) ? total : total + 1;

    this.minClientes = 0;
    this.maxClientes = (window.innerWidth > 300 && window.innerWidth <= 768) ? 0 : 2;

    console.log(this.maxClientes);

    for (var i = 0; i < totalpoints; i++) {
      this.qtdClientPoints[i] = (i == 0) ? 'active' : '';
    }
  }

  onPreviousClientes() {
    const less = (window.innerWidth > 300 && window.innerWidth <= 768) ? 1 : 3;
    const previous = this.maxClientes - less;
    this.maxClientes = (previous <= 0) ? 0 : (previous > (this.tblClients.length - 1)) ? 0 : previous;
    this.minClientes = (window.innerWidth > 300 && window.innerWidth <= 768) ? this.maxClientes : ((this.maxClientes - 2) > 0) ? this.maxClientes - 2 : 0;

    this.ptClientes = (this.minClientes == 1) ? 0 : this.ptClientes + 1;
    for (var i = 0; i < this.qtdClientPoints.length; i++) {
      this.qtdClientPoints[i] = (i == this.ptClientes) ? 'active' : '';
    }
  }

  onNextClientes() {
    const more = (window.innerWidth > 300 && window.innerWidth <= 768) ? 1 : 3;
    const maxClientes = this.maxClientes + more;
    this.maxClientes = ((this.tblClients.length - this.maxClientes) > 0) ? maxClientes : (window.innerWidth > 300 && window.innerWidth <= 768) ? 0 : 2;
    this.minClientes = (window.innerWidth > 300 && window.innerWidth <= 768) ? this.minClientes : (this.maxClientes > 0) ? this.maxClientes - 2 : 0;

    this.ptClientes = (this.minClientes == 0) ? 0 : this.ptClientes + 1;
    for (var i = 0; i < this.qtdClientPoints.length; i++) {
      this.qtdClientPoints[i] = (i == this.ptClientes) ? 'active' : '';
    }
  }

  async getInfoClientes() {
    var get_info_empresas = await this.homeService.getInfoEmpresas();
    console.log(get_info_empresas);
    if (get_info_empresas["exito"]) {
      this.tblInfoEmpresa = get_info_empresas["body"];
    }

    var total = (window.innerWidth > 300 && window.innerWidth <= 768) ? this.tblInfoEmpresa.length : Math.floor(this.tblInfoEmpresa.length / 3);
    var residue = (window.innerWidth > 300 && window.innerWidth <= 768) ? 0 : this.tblInfoEmpresa.length % 3;
    var totalpoints = (residue <= 0 && this.tblInfoEmpresa.length > 3) ? total : total + 1;

    this.minInfoClientes = 0;
    this.maxInfoClientes = (window.innerWidth > 300 && window.innerWidth <= 768) ? 0 : 0;

    for (var i = 0; i < totalpoints; i++) {
      this.qtdInfoClientPoints[i] = (i == 0) ? 'active' : '';
    }
  }

  onPreviousInfoClientes() {
    const more = 1;
    const maxInfoClientes = this.maxInfoClientes - more;
    this.maxInfoClientes = (maxInfoClientes > 0) ? maxInfoClientes : 0;
    this.minInfoClientes = this.maxInfoClientes;

    this.ptInfoClientes = (this.minInfoClientes == 0) ? 0 : this.ptInfoClientes + 1;
    for (var i = 0; i < this.qtdInfoClientPoints.length; i++) {
      this.qtdInfoClientPoints[i] = (i == this.ptInfoClientes) ? 'active' : '';
    }
  }

  onNextInfoClientes() {
    const more = 1;
    const maxInfoClientes = this.maxInfoClientes + more;
    this.maxInfoClientes = ((this.tblInfoEmpresa.length - maxInfoClientes) > 0) ? maxInfoClientes : 0;
    this.minInfoClientes = this.maxInfoClientes;

    this.ptInfoClientes = (this.minInfoClientes == 0) ? 0 : this.ptInfoClientes + 1;
    for (var i = 0; i < this.qtdInfoClientPoints.length; i++) {
      this.qtdInfoClientPoints[i] = (i == this.ptInfoClientes) ? 'active' : '';
    }
  }

  async getEvidencias() {
    var get_evidencia = await this.homeService.getEvidencia();
    console.log(get_evidencia);
    if (get_evidencia["exito"]) {
      this.tblEvidencia = get_evidencia["body"];
    }
  }

  startTimerServicios(): void {
    this.interval = setInterval(() => {
      this.onNextServicio();
      this.onNextInfoClientes();
    }, 30000);
  }

  startTimerClientes(): void {
    this.interval = setInterval(() => {
      this.onNextClientes();
    }, 10000);
  }
}
