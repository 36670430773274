import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FileUploader } from 'ng2-file-upload';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
declare var $: any;
declare var jQuery: any;

import { ConfiguracionService } from './configuracion.service';

const form_data_certificados: FormData = new FormData();
const form_data_service: FormData = new FormData();

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss']
})

export class ConfiguracionComponent implements OnInit {

  uri_certificado = environment.api_certificado;
  uri_servicio = environment.api_servicio;
  uri_empresa = environment.api_empresa;
  uri_evidencia = environment.api_evidencia;

  isCollapse = false;   // guardamos el valor

  emailinfo: string = "";
  emailrh: string = "";
  emailcontact: string = "";
  telefono: string = "";
  calle: string = "";
  nointerior: string = "";
  noexterior: string = "";
  zipcode: string = "";
  colonia: string = "";
  municipio: string = "";
  estado: string = "";
  pais: string = "";
  latitud: string = "";
  longitud: string = "";
  isSaveExtra = true;

  new_date: string = "";
  new_hour_start: string = "";
  new_hour_finish: string = "";
  tblHour: any = [];
  isSaveHour = true;
  isDeleteExtra = true;

  text_slogan: string = "";
  text_second: string = "";
  isSaveBanner = true;

  text_about_us: string = "";
  isSaveQuien = true;
  add_points_principal: string = "";
  tblSomos: any = [];
  isSaveSomos = true;
  isDeleteSomos = true;

  text_certificate: string = "";
  URLUploadImg = environment.apicertificado;
  public image_certificate: FileUploader = new FileUploader({
    url: this.URLUploadImg,
    disableMultipart: false,
    autoUpload: true,
    method: 'post',
    itemAlias: 'attachment',
    allowedFileType: ['image']
  });
  name_certificate: string = "";
  isSaveCertificados = true;
  isDeleteCertificados = true;

  tblCertificados: any = [];

  icon_ayudarte: string = "";
  titulo_ayudarte: string = "";
  texto_ayudarte: string = "";
  tblPasos: any = [];
  isSavePasos = true;
  isDeletePasos = true;

  URLUploadImgService = environment.apiservicio;
  public image_servicio: FileUploader = new FileUploader({
    url: this.URLUploadImgService,
    disableMultipart: false,
    autoUpload: true,
    method: 'post',
    itemAlias: 'attachment',
    allowedFileType: ['image']
  });
  name_servicio: string = "";
  titulo_servicio: string = "";
  texto_servicio: string = "";
  tblServicio: any = [];
  isSaveServicios = true;
  isDeleteServicios = true;

  name_empresa: string = "";
  URLUploadImgEmpresa = environment.apiempresa;
  public image_empresa: FileUploader = new FileUploader({
    url: this.URLUploadImgEmpresa,
    disableMultipart: false,
    autoUpload: true,
    method: 'post',
    itemAlias: 'attachment',
    allowedFileType: ['image']
  });
  text_empresa: string = "";
  tblEmpresa: any = [];
  isSaveEmpresa = true;
  isDeleteEmpresa = true;

  id_clientes_empresa:string = "";
  estrellas_empresa:string = "";
  nombre_empresa:string = "";
  texto_empresa: string = "";
  tblInfoEmpresa: any = [];
  isSaveInfoEmpresa = true;
  isDeleteInfoEmpresa = true;
  Arr = Array;

  name_evidencia: string = "";
  URLUploadImgEvidencia = environment.apievidencia;
  public image_evidencia: FileUploader = new FileUploader({
    url: this.URLUploadImgEvidencia,
    disableMultipart: false,
    autoUpload: true,
    method: 'post',
    itemAlias: 'attachment',
    allowedFileType: ['image']
  });
  tblEvidencia: any = [];
  isSaveEvidencia = true;
  isDeleteEvidencia = true;

  constructor(
    private router: Router,
    private http: HttpClient,
    private configuracionService: ConfiguracionService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem("user") === undefined) {
      this.router.navigate(['/login']);
    } else if (localStorage.getItem("user") == null) {
      this.router.navigate(['/login']);
    } else if (localStorage.getItem("user") == "undefined") {
      this.router.navigate(['/login']);
    } else if (localStorage.getItem("user") == "null") {
      this.router.navigate(['/login']);
    } else {

      this.getExtra();
      this.getHour();
      this.getBanner();
      this.getQuien();
      this.getSomos();
      this.getCertificados();
      this.getPasos();
      this.getServicio();
      this.getEmpresas();
      this.getInfoClientes();
      this.getEvidencia();
    }
  }

  toggleState() {
    this.isCollapse = this.isCollapse ? false : true;
  }

  async saveExtra() {
    let params = `emailinfo=${this.emailinfo}&telefono=${this.telefono}&calle=${this.calle}&nointerior=${this.nointerior}&noexterior=${this.noexterior}&zipcode=${this.zipcode}&colonia=${this.colonia}&municipio=${this.municipio}&estado=${this.estado}&pais=${this.pais}&emailcontact=${this.emailcontact}&emailrh=${this.emailrh}&latitud=${this.latitud}&longitud=${this.longitud}`;
    var postExtra = await this.configuracionService.postExtra(params);
    console.log(postExtra);

    if (postExtra["exito"]) {
      this.getExtra();
      this.isSaveExtra = false;
    }
  }

  async getExtra() {
    var postExtra = await this.configuracionService.getExtra();
    console.log(postExtra);
    if (postExtra["exito"]) {
      console.log(postExtra["body"][0]);
      this.emailinfo = postExtra["body"][0]["emailinfo"];
      this.telefono = postExtra["body"][0]["telefono"];
      this.calle = postExtra["body"][0]["calle"];
      this.nointerior = postExtra["body"][0]["nointerior"];
      this.noexterior = postExtra["body"][0]["noexterior"];
      this.zipcode = postExtra["body"][0]["zipcode"];
      this.colonia = postExtra["body"][0]["colonia"];
      this.municipio = postExtra["body"][0]["municipio"];
      this.estado = postExtra["body"][0]["estado"];
      this.pais = postExtra["body"][0]["pais"];
      this.emailcontact = postExtra["body"][0]["emailcontact"];
      this.emailrh = postExtra["body"][0]["emailrh"];
      this.latitud = postExtra["body"][0]["latitud"];
      this.longitud = postExtra["body"][0]["longitud"];
    }
  }

  async saveHour() {
    this.isDeleteExtra = true;
    this.isSaveHour = true;
    let params = `dia_semana=${this.new_date}&hora_inicio=${this.new_hour_start}&hora_fin=${this.new_hour_finish}`;
    var postHour = await this.configuracionService.postHour(params);
    console.log(postHour);
    if (postHour["exito"]) {
      this.getHour();
      this.isSaveHour = false;
    }
  }

  async getHour() {
    var get_hour = await this.configuracionService.getHour();
    console.log(get_hour);
    if (get_hour["exito"]) {
      this.tblHour = get_hour["body"];
    }
  }

  async deleteHour(id_hour) {
    this.isDeleteExtra = true;
    this.isSaveHour = true;
    var delete_hour = await this.configuracionService.deleteHour(id_hour);
    console.log(delete_hour);
    this.getHour();
    this.isDeleteExtra = false;
  }

  async getBanner() {
    var get_banner = await this.configuracionService.getBanner();
    console.log(get_banner);
    if (get_banner["exito"]) {

      this.text_slogan = get_banner["body"][0]["textone"];
      this.text_second = get_banner["body"][0]["texttwo"];
    }
  }

  async saveBanner() {
    this.isSaveBanner = true;
    let params = `textone=${this.text_slogan}&texttwo=${this.text_second}`;
    var postBanner = await this.configuracionService.postBanner(params);
    console.log(postBanner);
    if (postBanner["exito"]) {
      this.getBanner();
      this.isSaveBanner = false;
    }
  }

  async getQuien() {
    var get_quien = await this.configuracionService.getQuien();
    console.log(get_quien);
    if (get_quien["exito"]) {

      this.text_about_us = get_quien["body"][0]["text"];
    }
  }

  async saveQuien() {
    this.isSaveQuien = true;
    let params = `text=${this.text_about_us}`;
    var postQuien = await this.configuracionService.postQuien(params);
    console.log(postQuien);
    if (postQuien["exito"]) {
      this.getQuien();
      this.isSaveQuien = false;
    }
  }

  async getSomos() {
    var get_somos = await this.configuracionService.getSomos();
    console.log(get_somos);
    if (get_somos["exito"]) {
      this.tblSomos = get_somos["body"];
    }
  }

  async saveSomos() {
    this.isDeleteSomos = true;
    this.isSaveSomos = true;
    let params = `text=${this.add_points_principal}`;
    var postSomos = await this.configuracionService.postSomos(params);
    console.log(postSomos);
    if (postSomos["exito"]) {
      this.getSomos();
      this.isSaveSomos = false;
    }
  }

  async deleteSomos(id_hour) {
    this.isDeleteSomos = true;
    this.isSaveSomos = true;
    var delete_somos = await this.configuracionService.deleteSomos(id_hour);
    console.log(delete_somos);
    this.getSomos();
    this.isDeleteExtra = false;
  }

  onFileSelect(event, type) {
    var uriimage = (type == "certificado") ? this.URLUploadImg : (type == "servicios") ? this.URLUploadImgService : (type == "empresa") ? this.URLUploadImgEmpresa :  (type == "evidencia") ? this.URLUploadImgEvidencia : '';
    if (this.image_certificate.queue.length > 0) {

      for (var i = 0; i < this.image_certificate.queue.length; i++) {

        // this.saveimage(this.uploaderImage.queue[i], this.idScope)
        // console.log(this.idScope);
        form_data_certificados.append('avatar', this.image_certificate.queue[0].file.rawFile, this.image_certificate.queue[0].file.name);
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        // this.HttpClient.post(this.URLUploadImg, formData, { headers: headers })
        this.http.post(uriimage, form_data_certificados, { headers: headers })
          .subscribe(dataFiles => {

            console.log(dataFiles['body']);
            if(type == "certificado"){
              this.name_certificate = dataFiles['body'];
            }else if(type == "servicios"){
              this.name_servicio = dataFiles['body'];
            }else if (type == "empresa"){
              this.name_empresa = dataFiles['body'];
            }else if(type == "evidencia"){
              this.name_evidencia = dataFiles['body'];
            }
          });
      }
      this.image_certificate.queue.length = 0;
    }
  }

  async getCertificados() {
    var getCertificados = await this.configuracionService.getCertificados();
    console.log(getCertificados);
    if (getCertificados["exito"]) {
      this.tblCertificados = getCertificados["body"];
    }
  }

  async saveCertificados() {
    this.isSaveCertificados = true;
    this.isDeleteCertificados = true;
    let params = `imagen=${this.name_certificate}&nombre=${this.text_certificate}`;
    var postCertificados = await this.configuracionService.postCertificados(params);
    console.log(postCertificados);
    if (postCertificados["exito"]) {
      this.getCertificados();
      this.isSaveCertificados = false;
    }
  }

  async deleteCertificados(id_certificados) {
    this.isSaveCertificados = true;
    this.isDeleteCertificados = true;
    var deleteCertificados = await this.configuracionService.deleteCertificados(id_certificados);
    console.log(deleteCertificados);
    this.getCertificados();
    this.isDeleteCertificados = false;
  }

  async getPasos() {
    var get_pasos = await this.configuracionService.getPasos();
    console.log(get_pasos);
    if (get_pasos["exito"]) {
      this.tblPasos = get_pasos["body"];
    }
  }

  async savePasos() {
    this.isDeletePasos = true;
    this.isSavePasos = true;
    let params = `header=${this.titulo_ayudarte}&texto=${this.texto_ayudarte}&imagen=${this.icon_ayudarte}`;
    var post_pasos = await this.configuracionService.postPasos(params);
    console.log(post_pasos);
    if (post_pasos["exito"]) {
      this.getPasos();
      this.isSavePasos = false;
    }
  }

  async deletePasos(id_pasos) {
    this.isDeletePasos = true;
    this.isSavePasos = true;
    var delete_pasos = await this.configuracionService.deletePasos(id_pasos);
    console.log(delete_pasos);
    this.getPasos();
    this.isDeletePasos = false;
  }

  async getServicio() {
    var get_servicio = await this.configuracionService.getServicios();
    console.log(get_servicio);
    if (get_servicio["exito"]) {
      this.tblServicio = get_servicio["body"];
    }
  }

  async saveServicio() {
    this.isSaveServicios = true;
    this.isDeleteServicios = true;
    let params = `header=${this.titulo_servicio}&texto=${this.texto_servicio}&imagen=${this.name_servicio}`;
    var post_servicio = await this.configuracionService.postServicios(params);
    console.log(post_servicio);
    if (post_servicio["exito"]) {
      this.getServicio();
      this.isSaveServicios = false;
    }
  }

  async deleteServicios(id_servicios) {
    this.isDeleteServicios = true;
    this.isSaveServicios = true;
    var delete_servicio = await this.configuracionService.deleteServicios(id_servicios);
    console.log(delete_servicio);
    this.getServicio();
    this.isDeleteServicios = false;
  }

  async getEmpresas() {
    var get_empresas = await this.configuracionService.getEmpresas();
    console.log(get_empresas);
    if (get_empresas["exito"]) {
      this.tblEmpresa = get_empresas["body"];
    }
  }

  async saveEmpresas() {
    this.isSaveEmpresa = true;
    this.isDeleteEmpresa = true;
    let params = `nombre=${this.text_empresa}&imagen=${this.name_empresa}`;
    var post_empresa = await this.configuracionService.postEmpresas(params);
    console.log(post_empresa);
    if (post_empresa["exito"]) {
      this.getEmpresas();
      this.isSaveEmpresa = false;
    }
  }

  async deleteEmpresa(id_clientes) {
    this.isSaveEmpresa = true;
    this.isDeleteEmpresa = true;
    var delete_empresas = await this.configuracionService.deleteEmpresas(id_clientes);
    console.log(delete_empresas);
    this.getEmpresas();
    this.isDeleteEmpresa = false;
  }

  async getInfoClientes() {
    var get_info_empresas = await this.configuracionService.getInfoEmpresas();
    console.log(get_info_empresas);
    if (get_info_empresas["exito"]) {
      this.tblInfoEmpresa = get_info_empresas["body"];
    }
  }

  async saveInfoClientes() {
    this.isSaveInfoEmpresa = true;
    this.isDeleteInfoEmpresa = true;
    let params = `id_clientes=${this.id_clientes_empresa}&texto=${this.texto_empresa}&estrellas=${this.estrellas_empresa}&nombre=${this.nombre_empresa}`;
    var post_info_empresa = await this.configuracionService.postInfoEmpresas(params);
    console.log(post_info_empresa);
    if (post_info_empresa["exito"]) {
      this.getInfoClientes();
      this.isSaveInfoEmpresa = false;
    }
  }

  async deleteInfoClientes(id_infoclientes) {
    this.isSaveInfoEmpresa = true;
    this.isDeleteInfoEmpresa = true;
    var delete_info_empresas = await this.configuracionService.deleteInfoEmpresas(id_infoclientes);
    console.log(delete_info_empresas);
    this.getInfoClientes();
    this.isDeleteInfoEmpresa = false;
  }

  async getEvidencia() {
    var get_evidencia = await this.configuracionService.getEvidencia();
    console.log(get_evidencia);
    if (get_evidencia["exito"]) {
      this.tblEvidencia = get_evidencia["body"];
    }
  }

  async saveEvidencia() {
    this.isSaveEvidencia = true;
    this.isDeleteEvidencia = true;
    let params = `imagen=${this.name_evidencia}`;
    var post_evidencia = await this.configuracionService.postEvidencia(params);
    console.log(post_evidencia);
    if (post_evidencia["exito"]) {
      this.getEvidencia();
      this.isSaveEvidencia = false;
    }
  }

  async deleteEvidencia(id_evidencia) {
    this.isSaveEvidencia = true;
    this.isDeleteEvidencia = true;
    var delete_evidencia = await this.configuracionService.deleteEvidencia(id_evidencia);
    console.log(delete_evidencia);
    this.getEvidencia();
    this.isDeleteEvidencia = false;
  }
}
