import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isCollapse = false;   // guardamos el valor

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem("user") === undefined) {
      this.router.navigate(['/login']);
    } else if (localStorage.getItem("user") == null) {
      this.router.navigate(['/login']);
    } else if (localStorage.getItem("user") == "undefined") {
      this.router.navigate(['/login']);
    } else if (localStorage.getItem("user") == "null") {
      this.router.navigate(['/login']);
    }
  }

  toggleState() {
      this.isCollapse = this.isCollapse ? false : true;
  }
}
