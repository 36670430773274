<nav class="navbar navbar-expand-lg navbar-dark-mode bg-dark-mode">
    <a class="navbar-brand" href="#">
        <img src="assets/img/gamant.png" alt="logo" width="150">
    </a>
    <button class="navbar-toggler collapsed" type="button" (click)="toggleState()">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse w-100 order-3 dual-collapse2" [ngClass]="{ 'collapse': isCollapse }">
        <ul class="navbar-nav ml-auto">
            <li routerLinkActive="active" class="nav-item">
                <a class="nav-link" routerLink="/dashboard">Dashboard <span class="sr-only">(current)</span></a>
            </li>
            <li routerLinkActive="active" class="nav-item">
                <a class="nav-link" routerLink="/configuracion">Configaci&oacute;n</a>
            </li>
        </ul>
    </div>
</nav>

<section class="bg-aqua">

    <div class="container py-5">
        <div class="card">
            <div class="card-header">
                <h4 style="color: black;text-align: center;margin-top: 15px;">Configuración General</h4>
            </div>

            <div class="card-body">
                <div class="form-group row col-12">
                    <label for="emailinfo" class="col-sm-2 col-form-label" style="color: black;">Correo de
                        Informacion</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="emailinfo" name="emailinfo" [(ngModel)]="emailinfo"
                            placeholder="Correo de Informacion">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="emailcontact" class="col-sm-2 col-form-label" style="color: black;">Correo de
                        Contacto</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="emailcontact" name="emailcontact"
                            [(ngModel)]="emailcontact" placeholder="Correo de Contacto">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="emailrh" class="col-sm-2 col-form-label" style="color: black;">Correo de RH</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="emailrh" name="emailrh" [(ngModel)]="emailrh"
                            placeholder="Correo de RH">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="telefono" class="col-sm-2 col-form-label" style="color: black;">Telefono
                        Contacto</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="telefono" name="telefono" [(ngModel)]="telefono"
                            placeholder="Telefono Contacto">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="calle" class="col-sm-2 col-form-label" style="color: black;">Calle</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="calle" name="calle" [(ngModel)]="calle"
                            placeholder="Calle">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="nointerior" class="col-sm-2 col-form-label" style="color: black;">Numero Interior
                        (Opcional)</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="nointerior" name="nointerior"
                            [(ngModel)]="nointerior" placeholder="Numero Interior">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="noexterior" class="col-sm-2 col-form-label" style="color: black;">Numero
                        Exterior</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="noexterior" name="noexterior"
                            [(ngModel)]="noexterior" placeholder="Numero Exterior">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="zipcode" class="col-sm-2 col-form-label" style="color: black;">Codigo Postal</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="zipcode" name="zipcode" [(ngModel)]="zipcode"
                            placeholder="Codigo Postal">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="colonia" class="col-sm-2 col-form-label" style="color: black;">Colonia</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="colonia" name="colonia" [(ngModel)]="colonia"
                            placeholder="Colonia">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="municipio" class="col-sm-2 col-form-label" style="color: black;">Municipio</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="municipio" name="municipio" [(ngModel)]="municipio"
                            placeholder="Municipio">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="estado" class="col-sm-2 col-form-label" style="color: black;">Estado</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="estado" name="estado" [(ngModel)]="estado"
                            placeholder="Estado">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="pais" class="col-sm-2 col-form-label" style="color: black;">Pais</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="pais" name="pais" [(ngModel)]="pais"
                            placeholder="Pais">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="latitud" class="col-sm-2 col-form-label" style="color: black;">Latitud</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="latitud" name="latitud" [(ngModel)]="latitud"
                            placeholder="Latitud">
                    </div>
                </div>

                <div class="form-group row col-12">
                    <label for="longitud" class="col-sm-2 col-form-label" style="color: black;">Longitud</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="longitud" name="longitud" [(ngModel)]="longitud"
                            placeholder="Longitud">
                    </div>
                </div>

                <div class="alert alert-success" role="alert" [hidden]="isSaveExtra"> 
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a> 
                </div>

                <a (click)="saveExtra()" class="btn btn-primary pull-right">Guardar</a>
            </div>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">
                <h4 style="color: black;text-align: center;margin-top: 15px;">Horario de apertura</h4>
            </div>

            <div class="card-body">
                <div class="form-group row col-12">
                    <label for="new_hour" class="col-sm-2 col-form-label" style="color: black;">Nuevo horario</label>
                    <div class="col-sm-4">
                        <select class="select-css form-control" id="new_date" name="new_date" [(ngModel)]="new_date">
                            <option value="">-Selecciona una opción-</option>
                            <option value="Lunes">Lunes</option>
                            <option value="Martes">Martes</option>
                            <option value="Miercoles">Miercoles</option>
                            <option value="Jueves">Jueves</option>
                            <option value="Viernes">Viernes</option>
                            <option value="Sabado">Sabado</option>
                            <option value="Domingo">Domingo</option>
                        </select>
                    </div>
                    <div class="col-sm-2">
                        <input type="text" class="form-control" id="new_hour_start" name="new_hour_start"
                            [(ngModel)]="new_hour_start" placeholder="Hora Inicio">
                    </div>
                    <div class="col-sm-2">
                        <input type="text" class="form-control" id="new_hour_finish" name="new_hour_finish"
                            [(ngModel)]="new_hour_finish" placeholder="Hora Fin">
                    </div>
                    <div class="col-sm-2">
                        <a (click)="saveHour()" class="btn btn-outline-primary btn-sm">
                            <i class="fa fa-plus" style="color: #1df0f0;"></i>
                        </a>
                    </div>
                </div>

                <div class="form-group row col-12" *ngFor="let hours of tblHour; let i = index">
                    <label for="text_about_us" class="col-sm-2 col-form-label"
                        style="color: black;">{{i == 0 ? 'Actuales horarios' : '&nbsp;'}}</label>
                    <div class="col-sm-4">
                        <input type="text" readonly class="form-control" value="{{hours.dia_semana}}">
                    </div>
                    <div class="col-sm-2">
                        <input type="text" readonly class="form-control" value="{{hours.hora_inicio}}">
                    </div>
                    <div class="col-sm-2">
                        <input type="text" readonly class="form-control" value="{{hours.hora_fin}}">
                    </div>
                    <div class="col-sm-2">
                        <a (click)="deleteHour(hours.id_hora)" class="btn btn-outline-danger btn-sm">
                            <i class="fa fa-times" style="color: red;"></i>
                        </a>
                    </div>
                </div>


                <div class="alert alert-success" role="alert" [hidden]="isSaveHour"> 
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a> 
                </div>
                <div class="alert alert-success" role="alert" [hidden]="isDeleteExtra"> 
                    <a class="alert-link">Se ha eliminado su informaci&oacute;n con exito!</a> 
                </div>
            </div>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">
                <h4 style="color: black;text-align: center;margin-top: 15px;">Banner</h4>
            </div>

            <div class="card-body">
                <div class="form-group row col-12">
                    <label for="text_slogan" class="col-sm-2 col-form-label" style="color: black;">Texto Slogan</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="text_slogan" name="text_slogan"
                            [(ngModel)]="text_slogan" placeholder="Texto Slogan">
                    </div>
                </div>
                <div class="form-group row col-12">
                    <label for="text_second" class="col-sm-2 col-form-label" style="color: black;">Texto Secundario
                        (Visión/Misión/Sub-Slogan)</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="text_second" name="text_second"
                            [(ngModel)]="text_second" placeholder="Texto Secundario">
                    </div>
                </div>

                <a (click)="saveBanner()" class="btn btn-primary pull-right">Guardar</a>

                <div class="alert alert-success" role="alert" [hidden]="isSaveBanner">
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a>
                </div>
            </div>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">
                <h4 style="color: black;text-align: center;margin-top: 15px;">Quienes Somos</h4>
            </div>

            <div class="card-body">
                <div class="form-group row col-12">
                    <label for="text_about_us" class="col-sm-2 col-form-label" style="color: black;">Texto</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control" id="text_about_us" name="text_about_us"
                            [(ngModel)]="text_about_us" placeholder="Texto">
                    </div>

                    <div class="col-sm-2">
                        <a (click)="saveQuien()" class="btn btn-primary pull-right">
                            Modificar texto
                        </a>
                    </div>
                </div>
                <div class="alert alert-success row col-12" role="alert" [hidden]="isSaveQuien">
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a>
                </div>
                <div class="form-group row col-12">

                    <label for="text_second" class="col-sm-2 col-form-label" style="color: black;">Agregar Puntos
                        principales</label>
                    <div class="col-sm-10 row">
                        <div class="col-sm-10" style="color: black;">
                            <input type="text" class="form-control" id="add_points_principal"
                                name="add_points_principal" [(ngModel)]="add_points_principal"
                                placeholder="Agregar Nuevo Punto">
                        </div>
                        <div class="col-sm-2">
                            <a (click)="saveSomos()" class="btn btn-outline-primary btn-sm">
                                <i class="fa fa-plus" style="color: #1df0f0;"></i>
                            </a>
                        </div>
                    </div>

                    <div class="row col-12" *ngFor="let somos of tblSomos; let i = index" style="margin-top: 15px;">
                        <label for="text_second" class="col-sm-2 col-form-label"
                            style="color: black;">{{i == 0 ? 'Puntos principales' : '&nbsp;'}}</label>
                        <div class="col-sm-10 row">
                            <div class="col-sm-10" style="color: black;">
                                {{somos.text}}
                            </div>
                            <div class="col-sm-2">
                                <a (click)="deleteSomos(somos.id_quienessomospuntos)"
                                    class="btn btn-outline-danger btn-sm">
                                    <i class="fa fa-times" style="color: red;"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert alert-success row col-12" role="alert" [hidden]="isSaveSomos">
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a>
                </div>
                <div class="alert alert-success" role="alert" [hidden]="isDeleteSomos">
                    <a class="alert-link">Se ha eliminado su informaci&oacute;n con exito!</a>
                </div>
            </div>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">
                <h4 style="color: black;text-align: center;margin-top: 15px;">Cerficados</h4>
            </div>

            <div class="card-body">
                <div class="form-group row col-12">
                    <label for="text_about_us" class="col-sm-2 col-form-label" style="color: black;">
                        Cargar Imagen
                    </label>
                    <div class="col-sm-4">
                        <input type="hidden" id="name_certificate" name="name_certificate"
                            [(ngModel)]="name_certificate" />
                        <input type="file" class="form-control" id="image_certificate" name="image_certificate"
                            lang="in" ng2FileSelect [uploader]="image_certificate"
                            (change)="onFileSelect($event, 'certificado')">
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" id="text_certificate" name="text_certificate"
                            [(ngModel)]="text_certificate" placeholder="Texto">
                    </div>
                    <div class="col-sm-2">
                        <a (click)="saveCertificados()" class="btn btn-outline-primary btn-sm">
                            <i class="fa fa-plus" style="color: #1df0f0;"></i>
                        </a>
                    </div>
                </div>

                <div class="alert alert-success row col-12" role="alert" [hidden]="isSaveCertificados">
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a>
                </div>

                <div class="alert alert-success" role="alert" [hidden]="isDeleteCertificados">
                    <a class="alert-link">Se ha eliminado su informaci&oacute;n con exito!</a>
                </div>

                <div class="form-group row col-12" *ngFor="let certificados of tblCertificados; let i = index">
                    <label for="text_second" class="col-sm-2 col-form-label" style="color: black;">
                        {{i == 0 ? 'Imagenes actuales' : '&nbsp;'}}
                    </label>
                    <div class="col-sm-4" style="color: black;">
                        <img src="{{uri_certificado}}{{certificados.imagen}}" alt="" width="50" height="50">
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" readonly id="text_help" name="text_help"
                            value="{{certificados.nombre}}">
                    </div>
                    <div class="col-sm-2">
                        <a (click)="deleteCertificados(certificados.id_certificados)"
                            class="btn btn-outline-danger btn-sm">
                            <i class="fa fa-times" style="color: red;"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">
                <h4 style="color: black;text-align: center;margin-top: 15px;">Podemos apoyarte</h4>
            </div>

            <div class="card-body">
                <div class="form-group row col-12">
                    <label for="text_about_us" class="col-sm-2 col-form-label" style="color: black;">Sección</label>
                    <div class="col-sm-2">
                        <select class="fa-select select-css form-control" [(ngModel)]="icon_ayudarte">
                            <option value="" disabled>-Selecciona una opción-</option>
                            <option value="fa fa-phone">Telefono</option>
                            <option value="fa fa-mobile">Mobil</option>
                            <option value="fa fa-home">Casa</option>
                            <option value="fa fa-clipboard">Portapapel</option>
                            <option value="fa fa-check">Check</option>
                            <option value="fa fa-times">Equis (X)</option>
                            <option value="fa fa-ban">Prohibición</option>
                        </select>
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="titulo_ayudarte" name="titulo_ayudarte"
                            [(ngModel)]="titulo_ayudarte" placeholder="Titulo">
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="texto_ayudarte" name="texto_ayudarte"
                            [(ngModel)]="texto_ayudarte" placeholder="Contenido">
                    </div>
                    <div class="col-sm-2"><a (click)="savePasos()" class="btn btn-outline-primary btn-sm"><i
                                class="fa fa-plus" style="color: #1df0f0;"></i></a></div>
                </div>

                <div class="form-group row col-12" *ngFor="let pasos of tblPasos; let i = index">
                    <label for="text_about_us" class="col-sm-2 col-form-label"
                        style="color: black;">{{ i == 0 ? 'Sección' : ''}}</label>
                    <div class="col-sm-2">
                        <div class="icon"><i class="{{pasos.imagen}}"></i></div>
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" readonly value="{{pasos.header}}">
                    </div>
                    <div class="col-sm-3" style="color: black;">
                        {{pasos.texto}}
                    </div>
                    <div class="col-sm-2">
                        <a (click)="deletePasos(pasos.id_pasos)" class="btn btn-outline-danger btn-sm">
                            <i class="fa fa-times" style="color: red;"></i>
                        </a>
                    </div>
                </div>

                <div class="alert alert-success row col-12" role="alert" [hidden]="isSavePasos">
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a>
                </div>
                <div class="alert alert-success" role="alert" [hidden]="isDeletePasos">
                    <a class="alert-link">Se ha eliminado su informaci&oacute;n con exito!</a>
                </div>
            </div>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">
                <h4 style="color: black;text-align: center;margin-top: 15px;">Nuestros Servicios</h4>
            </div>

            <div class="card-body">
                <div class="form-group row col-12">
                    <label for="text_about_us" class="col-sm-2 col-form-label" style="color: black;">
                        Nuevo Servicio
                    </label>
                    <div class="col-sm-5">
                        <input type="hidden" id="name_servicio" name="name_servicio" [(ngModel)]="name_servicio" />
                        <input type="file" class="form-control" id="image_certificate" name="image_certificate"
                            lang="in" ng2FileSelect [uploader]="image_certificate"
                            (change)="onFileSelect($event, 'servicios')">
                    </div>
                    <div class="col-sm-5">
                        <input type="text" class="form-control" id="titulo_servicio" name="titulo_servicio"
                            [(ngModel)]="titulo_servicio" placeholder="Titulo">
                    </div>
                </div>
                <div class="form-group row col-12">
                    <div class="col-sm-12">
                        <div [froalaEditor] id="texto_servicio" name="texto_servicio" [(froalaModel)]="texto_servicio">
                            Ingrese su Contenido</div>
                    </div>
                </div>
                <div class="form-group row col-12">
                    <div class="col-sm-6">
                        <a (click)="saveServicio()" class="btn btn-outline-primary">
                            Agregar nuevo servicio
                        </a>
                    </div>
                </div>

                <div class="form-group row col-12" *ngFor="let servicios of tblServicio; let i = index">
                    <label for="text_about_us" class="col-sm-2 col-form-label"
                        style="color: black;">{{ i==0 ? 'Actuales Servicio' : ''}}
                    </label>
                    <div class="col-sm-2">
                        <img src="{{uri_servicio}}{{servicios.imagen}}" width="90" height="90">
                    </div>
                    <div class="col-sm-3">
                        <input type="text" readonly class="form-control" value="{{servicios.header}}">
                    </div>
                    <div class="col-sm-12" style="color: black;">
                        {{servicios.texto}}
                    </div>
                    <div class="col-sm-2">
                        <a (click)="deleteServicios(servicios.id_servicios)" class="btn btn-outline-danger btn-sm">
                            <i class="fa fa-times" style="color: red;"></i>
                        </a>
                    </div>
                </div>

                <div class="alert alert-success row col-12" role="alert" [hidden]="isSaveServicios">
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a>
                </div>
                <div class="alert alert-success" role="alert" [hidden]="isDeleteServicios">
                    <a class="alert-link">Se ha eliminado su informaci&oacute;n con exito!</a>
                </div>
            </div>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">
                <h4 style="color: black;text-align: center;margin-top: 15px;">Nuestros Empresas</h4>
            </div>

            <div class="card-body">
                <div class="form-group row col-12">
                    <label for="text_about_us" class="col-sm-2 col-form-label" style="color: black;">
                        Nuevo Empresas
                    </label>
                    <div class="col-sm-4">
                        <input type="hidden" id="name_empresa" name="name_empresa" [(ngModel)]="name_empresa" />
                        <input type="file" class="form-control" id="image_certificate" name="image_certificate"
                            lang="in" ng2FileSelect [uploader]="image_certificate"
                            (change)="onFileSelect($event, 'empresa')">
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control" id="text_empresa" name="text_empresa"
                            [(ngModel)]="text_empresa" placeholder="Nombre de la empresa">
                    </div>
                    <div class="col-sm-2">
                        <a (click)="saveEmpresas();" class="btn btn-outline-primary btn-sm">
                            <i class="fa fa-plus" style="color: #1df0f0;"></i>
                        </a>
                    </div>
                </div>

                <div class="alert alert-success row col-12" role="alert" [hidden]="isSaveEmpresa">
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a>
                </div>
                <div class="alert alert-success" role="alert" [hidden]="isDeleteEmpresa">
                    <a class="alert-link">Se ha eliminado su informaci&oacute;n con exito!</a>
                </div>

                <div class="form-group row col-12" *ngFor="let empresa of tblEmpresa; let i = index">
                    <label for="text_about_us" class="col-sm-2 col-form-label" style="color: black;">
                        {{i==0 ? 'Actuales Empresas' : ''}}
                    </label>
                    <div class="col-sm-4">
                        <img src="{{uri_empresa}}{{empresa.imagen}}" width="290" height="90">
                    </div>
                    <div class="col-sm-4">
                        <input type="text" readonly class="form-control" value="{{empresa.nombre}}">
                    </div>
                    <div class="col-sm-2">
                        <a (click)="deleteEmpresa(empresa.id_clientes)" class="btn btn-outline-danger btn-sm">
                            <i class="fa fa-times" style="color: red;"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">
                <h4 style="color: black;text-align: center;margin-top: 15px;">Lo Que Dicen Nuestras Empresas</h4>
            </div>

            <div class="card-body">
                <div class="form-group row col-12">
                    <label for="text_about_us" class="col-sm-2 col-form-label" style="color: black;">Empresas</label>
                    <div class="col-sm-2">
                        <select class="fa-select select-css form-control" id="id_clientes_empresa" name="id_clientes_empresa"
                            [(ngModel)]="id_clientes_empresa">
                            <option value="" disabled>-Selecciona una opción-</option>
                            <option *ngFor="let empresa of tblEmpresa; let i = index" value="{{empresa.id_clientes}}">{{empresa.nombre}}</option>
                        </select>
                    </div>
                    <div class="col-sm-3">
                        <input type="number" class="form-control" id="estrellas_empresa" name="estrellas_empresa"
                            [(ngModel)]="estrellas_empresa" placeholder="Estrellas">
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control" id="nombre_empresa" name="nombre_empresa" [(ngModel)]="nombre_empresa"
                            placeholder="Encargado">
                    </div>
                    <div class="form-group row col-12">
                        <div class="col-sm-12">
                            <div [froalaEditor] id="texto_empresa" name="texto_empresa" [(froalaModel)]="texto_empresa">
                                Ingrese lo que piensa el cliente
                            </div>
                        </div>
                    </div>
                    <div class="form-group row col-6">
                        <a (click)="saveInfoClientes()" class="btn btn-outline-primary btn-sm">
                            <!--<i class="fa fa-plus" style="color: #1df0f0;"></i>-->
                                Agregar nuevo comentario
                        </a>
                    </div>
                </div>

                <div class="alert alert-success row col-12" role="alert" [hidden]="isSaveInfoEmpresa">
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a>
                </div>
                <div class="alert alert-success" role="alert" [hidden]="isDeleteInfoEmpresa">
                    <a class="alert-link">Se ha eliminado su informaci&oacute;n con exito!</a>
                </div>

                <div class="form-group row col-12" *ngFor="let infoempresa of tblInfoEmpresa; let i = index">
                    <label for="text_about_us" class="col-sm-2 col-form-label" style="color: black;">
                        Actuales Empresas
                    </label>
                    <div class="col-sm-2">
                        <input type="text" readonly class="form-control" value="{{infoempresa.nombre_cliente}}">
                    </div>
                    <div class="col-sm-2">
                        <ul class="list-inline small mb-4" *ngIf="infoempresa.estrellas == 5">
                            <li class="list-inline-item m-0" *ngFor="let i of Arr(5).fill(1)"><i class="fa fa-star text-warning"></i></li>
                        </ul>
                        <ul class="list-inline small mb-4" *ngIf="infoempresa.estrellas == 4">
                            <li class="list-inline-item m-0" *ngFor="let i of Arr(4).fill(1)"><i class="fa fa-star text-warning"></i></li>
                            <i class="fa fa-star-o" style="color: black"></i>
                        </ul>
                        <ul class="list-inline small mb-4"  *ngIf="infoempresa.estrellas == 3">
                            <li class="list-inline-item m-0" *ngFor="let i of Arr(3).fill(1)"><i class="fa fa-star text-warning"></i></li>
                            <i class="fa fa-star-o" style="color: black"></i>
                            <i class="fa fa-star-o" style="color: black"></i>
                        </ul>
                        <ul class="list-inline small mb-4" *ngIf="infoempresa.estrellas == 2">
                            <li class="list-inline-item m-0" *ngFor="let i of Arr(2).fill(1)"><i class="fa fa-star text-warning"></i></li>
                            <i class="fa fa-star-o" style="color: black"></i>
                            <i class="fa fa-star-o" style="color: black"></i>
                            <i class="fa fa-star-o" style="color: black"></i>
                        </ul>
                        <ul class="list-inline small mb-4" *ngIf="infoempresa.estrellas == 1">
                            <li class="list-inline-item m-0" *ngFor="let i of Arr(4).fill(1)"><i class="fa fa-star text-warning"></i></li>
                            <i class="fa fa-star-o" style="color: black"></i>
                            <i class="fa fa-star-o" style="color: black"></i>
                            <i class="fa fa-star-o" style="color: black"></i>
                            <i class="fa fa-star-o" style="color: black"></i>
                        </ul>
                    </div>
                    <div class="col-sm-3">
                        <input type="text" readonly class="form-control" value="{{infoempresa.nombre}}" placeholder="Nombre del responsable">
                    </div>
                    <div class="col-sm-12" style="color: black;">
                        {{infoempresa.texto}}
                    </div>
                    <div class="col-sm-2">
                        <a (click)="deleteInfoClientes(infoempresa.id_infoclientes)" class="btn btn-outline-danger btn-sm">
                            <i class="fa fa-times" style="color: red;"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" style="margin-top: 15px;">
            <div class="card-header">
                <h4 style="color: black;text-align: center;margin-top: 15px;">Nuestros Trabajos</h4>
            </div>

            <div class="card-body">
                <div class="form-group row col-12">
                    <label for="text_about_us" class="col-sm-2 col-form-label" style="color: black;">
                        Nuevo Trabajos
                    </label>
                    <div class="col-sm-6">
                        <input type="hidden" id="name_evidencia" name="name_evidencia"
                            [(ngModel)]="name_evidencia" />
                        <input type="file" class="form-control" id="image_certificate" name="image_certificate"
                            lang="in" ng2FileSelect [uploader]="image_certificate"
                            (change)="onFileSelect($event, 'evidencia')">
                    </div>
                    <div class="col-sm-2">
                        <a (click)="saveEvidencia()" class="btn btn-outline-primary btn-sm">
                            <i class="fa fa-plus" style="color: #1df0f0;"></i>
                        </a>
                    </div>
                </div>

                <div class="alert alert-success row col-12" role="alert" [hidden]="isSaveEvidencia">
                    <a class="alert-link">Se ha almacenado su informaci&oacute;n con exito!</a>
                </div>
                <div class="alert alert-success" role="alert" [hidden]="isDeleteEvidencia">
                    <a class="alert-link">Se ha eliminado su informaci&oacute;n con exito!</a>
                </div>

                <div class="form-group row col-12" *ngFor="let evidencia of tblEvidencia; let i = index">
                    <label for="text_about_us" class="col-sm-2 col-form-label" style="color: black;">{{i==0 ? 'Actuales Trabajos' : ''}}</label>
                    <div class="col-sm-6">
                        <div class="box" style="margin: 0 -15px;">
                            <a title=""><img src="{{uri_evidencia}}{{evidencia.imagen}}" alt="" class="img-responsive" width="390" height="190"></a>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <a (click)="deleteEvidencia(evidencia.id_evidencia)" class="btn btn-outline-danger btn-sm"><i class="fa fa-times" style="color: red;"></i></a>
                    </div>
                </div>

                <!--<a href="#" class="btn btn-primary pull-right">Guardar</a>-->
            </div>
        </div>
    </div>
</section>