// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  /* LOCAL */
  //apiuri: 'http://localhost:8080/sicgaman/Controller/',
  
  /* QA */
  apiuri: 'http://www.coffeholik.com/sicgamanbd/Controller/',
  apicertificado : 'http://www.coffeholik.com/sicgamanbd/Models/Certificados.php',
  api_certificado : 'http://www.coffeholik.com/sicgamanbd/Images/Certificados/',
  apiservicio : 'http://www.coffeholik.com/sicgamanbd/Models/Servicio.php',
  api_servicio : 'http://www.coffeholik.com/sicgamanbd/Images/Servicio/',
  apiempresa : 'http://www.coffeholik.com/sicgamanbd/Models/Empresa.php',
  api_empresa : 'http://www.coffeholik.com/sicgamanbd/Images/Empresa/',
  apievidencia : 'http://www.coffeholik.com/sicgamanbd/Models/Evidencia.php',
  api_evidencia : 'http://www.coffeholik.com/sicgamanbd/Images/Evidencia/',

  apicv : 'http://www.coffeholik.com/sicgamanbd/Models/Curriculum.php',
  api_cv : 'http://www.coffeholik.com/sicgamanbd/Images/Curriculum/',

  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
