<header class="header">

    <nav class="navbar navbar-expand-lg navbar-light py-3 bg-white">
      <div class="container">
        <a class="navbar-brand">
          <img src="assets/img/gamant.png" alt="" width="150">
        </a>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item mx-2">
              <!-- Link--><a class="nav-link" style="cursor: pointer;" [routerLink]="'/home'">Inicio</a>
            </li>
            <li class="nav-item mx-2">
              <!-- Link--><a class="nav-link" style="cursor: pointer;" [routerLink]="'/contact'">Contactanos</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  
  <!-- Contact Section -->
  <section class="bg-aqua">
    <div class="container py-5">
      <div class="card rounded-0 border-0">
        <div class="card-body p-4 p-lg-5">
          <div class="row py-5">
            <div class="col-lg-5">
              <h2 style="color: gray;">Unete <span class="text-primary">con </span>nosotros</h2>
                <p class="mb-5 text-muted">
                <strong>La base de este éxito son nuestros empleados:  </strong><br/>
                <small>empleados comprometidos, a quienes les encanta afrontar nuevos retos, y quieren desarrollar y formar parte activa del futuro de la tecnología de medición.</small><br/><br/>
                
                <strong>Destacamos el trabajo en equipo en mayúsculas: </strong><br/>
                <small>desde el menos experimentado pasando por el especialista hasta el Manager con dilatada experiencia trabajamos juntos como una sola entidad.</small><br/>
                
                <small>Intercambiamos ideas, nos ayudamos mutuamente y nos interesa la persona que está detrás de su puesto de trabajo.</small><br/><br>
                
                <small><strong>Es por eso que nuestros empleados pueden beneficiarse de nuestras oportunidades de promoción para su crecimiento profesional y personal.</strong></small>
                </p>
            </div>
            <div class="col-lg-7">
              <form action="#">
                <div class="row">
                  <div class="form-group col-lg-6">
                    <input class="form-control" type="text" [(ngModel)]="names" id="names" name="names"
                      placeholder="Nombre(s)"
                      [ngClass]="{ 'is-invalid': names == '' && msgError == true ? true : false }" />
                    <div *ngIf="names == '' && msgError == true" class="invalid-feedback">Este campo es obligatorio</div>
                  </div>
                  <div class="form-group col-lg-6">
                    <input class="form-control" type="tel" [(ngModel)]="cellphone" id="cellphone"
                      name="cellphone" placeholder="Celular de contacto"
                      [ngClass]="{ 'is-invalid': cellphone == '' && msgError == true ? true : false }" />
                    <div *ngIf="cellphone == '' && msgError == true" class="invalid-feedback">Este campo es obligatorio
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <input class="form-control" type="tel" [(ngModel)]="phonecontact" id="phonecontact" name="phonecontact"
                       placeholder="Telefono de contacto"
                      [ngClass]="{ 'is-invalid': phonecontact == '' && msgError == true ? true : false }" />
                    <div *ngIf="phonecontact == '' && msgError == true" class="invalid-feedback">Este campo es obligatorio
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <input class="form-control" type="email" [(ngModel)]="email" id="email" name="email"
                      placeholder="Email" [ngClass]="{ 'is-invalid': email == '' && msgError == true ? true : false }" />
                    <div *ngIf="email == '' && msgError == true" class="invalid-feedback">Este campo es obligatorio</div>
                  </div>
                  <label class="form-group col-3" style="color: gray;">Anexa tu cv:</label>
                  <div class="form-group col-9">
                    <input type="hidden" id="name_cv" name="name_cv"
                    [(ngModel)]="name_cv" />
                    <input type="file" class="form-control" id="image_cv" name="image_cv"
                    lang="in" ng2FileSelect [uploader]="image_cv"
                    (change)="onFileSelect($event)">
                    <!--<input class="form-control" type="tel" [(ngModel)]="phonecontact" id="phonecontact" name="phonecontact"
                       placeholder="Telefono de contacto"
                      [ngClass]="{ 'is-invalid': phonecontact == '' && msgError == true ? true : false }" />
                    <div *ngIf="phonecontact == '' && msgError == true" class="invalid-feedback">Este campo es obligatorio
                    </div>-->
                  </div>
                  <div class="form-group col-lg-12">
                    <!--<textarea class="form-control" id="message" name="message" rows="5" placeholder="Mensaje"
                      (change)="doTextareaValueChange($event)"  [(ngModel)]="message" 
                      [ngClass]="{ 'is-invalid': message == '' && msgError == true ? true : false }">{{message}}</textarea>-->
                    <div *ngIf="message == '' && msgError == true" class="invalid-feedback">Este campo es obligatorio
                    </div>
                  </div>
                  <div class="form-group col-lg-12">
                    <button class="btn btn-outline-primary" type="button" (click)="sendMsg()">Enviar solicitud</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Map Section -->
  <!--<section class="gmap" id="map"></section>-->
  <section class="mapouter">
    <div class="gmap_canvas row">
      <iframe class="col-12" style="width: 100%;" id="gmap_canvas" src="https://maps.google.com/maps?q=Eloy%20Cavazos%20%202205%2C%20La%20Quinta%2C%20Guadalupe%2C%20Nuevo%20Le%C3%B3n%2C%20M%C3%A9xico%2067170&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      <a href="https://www.embedgooglemap.net/en/"></a>
    </div>
    <style>.mapouter{position:relative;text-align:right;}.gmap_canvas {overflow:hidden;background:none!important;}</style>
  </section>
  
  <footer class="text-white bg-cover bg-center" style="background: url(img/hero-banner-1.jpg)">
    <div class="dark-overlay">
        <div class="overlay-content">
            <div class="container py-5">
                <div class="row py-5">
                    <div class="col-lg-4 col-md-6">
                        <img class="mb-4" src="assets/img/logo-white.png" alt="" width="120">
                        <p class="text-light text-small mb-4">{{text_slogan}}</p>
  
                        <p class="mb-1" (click)="mailMe()" target="_top" style="cursor: pointer;">
                            <i class="fa fa-envelope mr-3 fa-fw" style="color: #1df0f0;"></i>
                            <span class="text-small">{{emailinfo}}</span>
                        </p>
                        <p class="mb-1">
                            <i class="fa fa-mobile mr-3 fa-fw" style="color: #1df0f0;"></i>
                            <span class="text-small">{{telefono}}</span>
                        </p>
                        <p class="mb-1" (click)="goDirection()" style="cursor: pointer;">
                            <i class="fa fa-map-marker mr-3 fa-fw" style="color: #1df0f0;"></i>
                            <span class="text-small">{{calle}} {{nointerior}} {{noexterior}}, {{colonia}},
                                {{municipio}}, {{estado}}, {{pais}} {{zipcode}}</span>
                        </p>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <h6 class="text-uppercase mt-3 mb-4" style="color: #1df0f0;">Quick <span
                                class="text-white">links</span>
                        </h6>
                        <ul class="list-unstyled">
                            <li class="mb-2"><a class="footer-link" style="cursor: pointer;" [routerLink]="'/home'">Podemos apoyarte!</a></li>
                            <li class="mb-2"><a class="footer-link" href="#">Contactanos!</a></li>
                            <li class="mb-2"><a class="footer-link" href="#">Unete a nuestro equipo!</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <h6 class="text-uppercase mt-3 mb-4" style="color: #1df0f0;">
                            Nuestros <span class="text-white">servicios</span>
                        </h6>
                        <ul class="list-unstyled">
                            <li class="mb-1" *ngFor="let servicios of tblServicio; let i = index">
                                <a class="footer-link" style="cursor: pointer;" (click)="viewService(content, i)">
                                    {{servicios.header}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <h6 class="text-uppercase mt-3 mb-4" style="color: #1df0f0;">
                            Horarios de<span class="text-white"> apertura</span>
                        </h6>
                        <ul class="list-unstyled mb-0">
                            <li *ngFor="let hours of tblHour; let i = index"
                                class="d-flex align-items-center justify-content-between {{i == 0 ? 'pb-2' : 'py-1'}}">
                                <p class="text-small mb-0">{{hours.dia_semana}} </p>
                                <span class="text-small mb-0"
                                    style="color: {{hours.hora_inicio != 'Cerrado' ? '' : '#1df0f0;'}}">{{hours.hora_inicio}}
                                    {{hours.hora_inicio != 'Cerrado' ? '-' : ''}}
                                    {{hours.hora_inicio != 'Cerrado' ? hours.hora_fin : ''}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container text-center">
                <div class="border-top py-4">
                    <p class="mb-0 text-white text-small">
                        &copy; All rights reserved. designed by <a [routerLink]="'/home'">CoffeHolik</a>.</p>
                </div>
            </div>
        </div>
    </div>
  </footer>
  
  <ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{text_modal}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div [innerHTML]="body_modal"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-white" (click)="modal.close('close')">Cerrar</button>
    </div>
  </ng-template>