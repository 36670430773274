import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  uriuser = environment.apiuri+ 'UsersController.php';

  constructor(
    private httpClient: HttpClient,
    public router: Router
) {}
  async loginUser(username: String, psw: String): Promise<any> {
    const response = await this.httpClient.get(`${this.uriuser}?username=${username}&psw=${psw}&type=login`).toPromise();
    return response;
  }
}
