<div class="limiter">
    <div class="container-login100">
        <div class="wrap-login100 p-t-85 p-b-20">
            <form class="login100-form validate-form">
                <span class="login100-form-title p-b-70">
                    Bienvenidos al portal SICGMAN
                </span>
                <span>
                    <img src="assets/img/gamant.png" alt="AVATAR" style="width: 400px;height: 197px;">
                </span>

                <div class="wrap-input100 validate-input m-t-85 m-b-35" data-validate="Enter password">
                    <input type="text" id="username" name="username" [(ngModel)]="username" class="input100" [ngClass]="{ 'is-invalid': username == '' && msjerror != '' ? true : false }" />
                    <span class="focus-input100" data-placeholder="Usuario"></span>
                    <div *ngIf="username == '' && msjerror != ''" class="invalid-feedback">{{msjerror}}</div>
                </div>

                <div class="wrap-input100 validate-input m-b-50" data-validate="Enter password">
                    <input type="password" id="pass" name="pass" [(ngModel)]="pass" class="input100" [ngClass]="{ 'is-invalid': pass == '' && msjerror != '' ? true : false }" />
                    <span class="focus-input100" data-placeholder="Password"></span>
                    <div *ngIf="pass == '' && msjerror != ''" class="invalid-feedback">{{msjerror}}</div>
                </div>

                <div class="container-login100-form-btn">
                    <button class="login100-form-btn" (click)="logIn()">
                        Iniciar Sesión
                    </button>
                </div>
                <div class="container-login100-form-btn" style="text-align: center; margin-top: 10px;">
                    <div *ngIf="msjerrorbd != ''" style="width: 100%;font-size: 1em;color: #dc3545;">{{msjerrorbd}}</div>
                </div>
            </form>
        </div>
    </div>
</div>